import React from "react"
import Page from "src/components/page"
import Section from "src/components/section"
import Markup from "src/components/markup"
import { usePrivacyStore } from "src/state/privacy"

const PrivacyPage = () => {
  const { meta, title, subtitle, content } = usePrivacyStore()

  return (
    <Page {...meta}>
      <Section className="section-1">
        <h2 className="title">{title}</h2>
        <p className="subtitle">{subtitle}</p>
        <div className="content">
          <Markup>{content}</Markup>
        </div>
      </Section>
    </Page>
  )
}

export default PrivacyPage
